.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  /* Fix flickering issue in Safari */
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}

@media only screen and (max-width: 768px) {
  .swiper-button-next::after, .swiper-button-prev::after {
    font-size: 20px !important;
  }

  .swiper-button-next {
    right: 0px !important;
  }

  .swiper-button-prev {
    left: 0px !important;
  }
}

@media only screen and (min-width: 768px) {
  .swiper-button-next::after {
    font-size: 25px;
    padding-right: 50px;
  }
  
  .swiper-button-prev::after {
    font-size: 25px;
    padding-left: 50px;
  }
}
/* 
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
